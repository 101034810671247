import { ReactNode, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Container, Box, Typography, Tabs, Tab, AppBar, Toolbar, Button, Divider, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Accounts from "./accounts";
import Organizations from "./organizations";
import Locations from "./locations";
import Tenants from "./tenants";
import GlobalSearch from "../../components/GlobalSearch";
import AccountsColumn from "./columnView/accountsColumn";
import OrganizationsColumn from "./columnView/organizationsColumn";
import LocationsColumn from "./columnView/locationsColumn";
import TenantsColumn from "./columnView/tenantsColumn";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

interface ISelectedColumn {
  id: number;
  name: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dataplane-panel-${index}`}
      aria-labelledby={`dataplane-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `dataplane-${index}`,
    "aria-controls": `dataplane-panel-${index}`,
  };
};

const AccountsLayout = () => {
  const { t } = useTranslation("common");
  const [tabValue, setTabValue] = useState(0);
  const [isColumnView, setColumnView] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState<ISelectedColumn>({} as any);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderAppBar = () => (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${240}px)` },
        ml: { sm: `${240}px` },
        backgroundColor: "#18191D",
        boxShadow: "none",
        backgroundImage: "none",
      }}
    >
      <Toolbar>
        <Box>Accounts</Box>
        <Box sx={{ flexGrow: 1 }} />
        <GlobalSearch />
        <Box>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={() => {
              setColumnView(!isColumnView);
            }}
          >
            View
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );

  return (
    <>
      {/* {renderAppBar()} */}
      <Divider></Divider>
      {isColumnView && (
        <>
          <Grid container sx={{ minWidth: 900 }}>
            <Grid item xs={3}>
              <AccountsColumn setSelectedColumn={setSelectedColumn} />
            </Grid>
            <Grid item xs={3}>
              {selectedColumn.id >= 1 && <OrganizationsColumn setSelectedColumn={setSelectedColumn} />}
            </Grid>
            <Grid item xs={3}>
              {selectedColumn.id >= 2 && <LocationsColumn setSelectedColumn={setSelectedColumn} />}
            </Grid>
            <Grid item xs={3}>
              {selectedColumn.id >= 3 && <TenantsColumn setSelectedColumn={setSelectedColumn} />}
            </Grid>
          </Grid>
        </>
      )}
      {!isColumnView && (
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ marginBottom: "20px" }}>
            <Typography color="#777" gutterBottom>
              {t("common:accounts.title")}
            </Typography>
          </Breadcrumbs>
          <Container>
            <Box component="main">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label="Accounts" {...a11yProps(0)} />
                  <Tab label="Organizations" {...a11yProps(1)} />
                  <Tab label="Locations" {...a11yProps(2)} />
                  <Tab label="Tenants" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <Accounts />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Organizations />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Locations />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <Tenants />
              </TabPanel>
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default AccountsLayout;
