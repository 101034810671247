import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button, Card, CardHeader, CardContent, Box, Grid, Divider, Breadcrumbs, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridCallbackDetails, GridColDef, GridFilterModel } from "@mui/x-data-grid";
import { FormInput } from "../../components/FormInput";
import useNotifications from "../../components/NotificationProvider";
import RoleService from "../../services/role.service";
import { FormAutocomplete } from "../../components/FormAutoComplete";
import { constructRolePermissionDetail } from "../../utils/utils";
import { IRowFilter } from "../../types/app/common.type";
import { IRole } from "../../types/api/role.type";
import PermissionService from "../../services/permission.service";
import { IPermission, IPermissionsResponse } from "../../types/api/permission.type";

interface ITableRowsState {
  page: number;
  pageSize: number;
}

const permissionColumns: GridColDef[] = [
  { field: "permission_id", headerName: "Permission ID", flex: 1 },
  { field: "permission_desc", headerName: "Permission description", flex: 1 },
  {
    field: "page",
    headerName: "Page",
    flex: 1,
    renderCell: (params) => <>{params.row.operations?.page}</>,
  },
  {
    field: "object",
    headerName: "Object",
    flex: 1,
    renderCell: (params) => <>{params.row.operations?.object}</>,
  },
  {
    field: "operations",
    headerName: "Operations",
    flex: 2,
    renderCell: (params) => <>{params.row.operations?.operations.join(", ")}</>,
  },
];

export default function Roles() {
  const { t } = useTranslation("common");
  const {
    control,
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [rowsState, setRowsState] = useState<ITableRowsState>({
    page: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState<number>(0);
  const [rowFilter, setRowFilter] = useState<IRowFilter>({});
  const [tableRows, setTableRows] = useState<IRole[]>([]);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<IPermission[]>([]);
  const [searchText, setSearchText] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const { setNotification } = useNotifications();

  const columns: GridColDef[] = [
    { field: "role_id", headerName: "Role ID", flex: 1 },
    { field: "role_desc", headerName: "Role description", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        if (params.row.deleted) {
          return <></>;
        }
        return (
          <>
            <EditIcon
              sx={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => {
                setOpenRoleDialog(true);
                handleEditClick({ ...params.row });
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsState, rowFilter, showDeleted]);

  useEffect(() => {
    if (searchText) {
      const delayDebounceFn = setTimeout(() => {
        setRowFilter({ role_name: searchText });
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleEditClick = async (roleData: IRole) => {
    try {
      const roleResponse: IRole = (await RoleService.getRoleByID(roleData.role_id)).data;
      reset({ ...roleResponse });
      setSelectedPermissions(roleResponse.permissions || []);
    } catch (error) {}
  };

  const getRoles = async () => {
    try {
      const result = await RoleService.getRoles({
        count_per_page: rowsState.pageSize,
        page_no: rowsState.page + 1,
        filter: rowFilter,
        show_deleted: showDeleted,
      });
      setTotalRows(result.data?.total);
      setTableRows(result.data?.results);
    } catch (e) {
      console.error("error", e);
    }
  };

  const getPermissions = async () => {
    try {
      const result: IPermissionsResponse =
        await PermissionService.getPermissions({
          count_per_page: 1000,
          page_no: 1,
          filter: {},
          show_deleted: false,
        });
      setPermissions(result.data?.results);
    } catch (e) {
      console.error("error", e);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      let role_id = values.role_id;
      const constructedData = constructRolePermissionDetail(values);
      if (role_id) {
        await RoleService.editRolePermissions(constructedData, role_id);
      } else {
        await RoleService.createRole(constructedData);
      }
      setOpenRoleDialog(false);
      setNotification({
        message: `Role ${role_id ? "updated" : "created"} successfully`,
        open: true,
      });
      getPermissions();
      reset({});
    } catch (e) {
      setNotification({
        message: "Error occurred",
        open: true,
        type: "error",
      });
      console.error("error", e);
    }
  };

  const onFilterChange = (model: GridFilterModel, details: GridCallbackDetails) => {
    console.log("filter data---", model, details);
    setRowFilter({ [model.items[0].columnField]: model.items[0].value });
  };

  const handleSearchChange = (searchText: string) => {
    setSearchText(searchText);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setRowFilter({});
  };

  return (
    <>
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ marginBottom: "20px" }}>
        <Typography color="#777">Roles and Permissions</Typography>
      </Breadcrumbs>
      <Card sx={{ backgroundColor: "#2A2B31" }}>
        <CardHeader title={t("common:roles.title")} />
        <CardContent
          sx={{
            paddingBottom: "16px !important",
            "& .deleted-row": {
              backgroundColor: "#4c3939",
            },
          }}
        >
          <DataGrid
            autoHeight
            rows={tableRows}
            columns={columns}
            getRowId={(row) => row.role_id}
            rowsPerPageOptions={[rowsState.pageSize]}
            rowCount={totalRows}
            disableSelectionOnClick
            getRowClassName={(params) => (params.row.deleted ? "deleted-row" : "")}
            paginationMode="server"
            // sortingMode="server"
            // onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            {...rowsState}
            onPageChange={(page) => setRowsState((prev: ITableRowsState) => ({ ...prev, page }))}
            // components={{
            //   Toolbar: CustomTableToolbar,
            // }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
              },
              toolbar: {
                setFilterButtonEl,
                searchValue: searchText,
                onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleSearchChange(event.target.value);
                },
                showDeleted: showDeleted,
                onShowDeletedChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  console.log(event.target.checked);
                  setShowDeleted(event.target.checked);
                },
                clearSearch: () => handleClearSearch(),
                showDeletedCheckbox: false,
              },
            }}
          />
        </CardContent>
      </Card>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={openRoleDialog}
        onClose={() => {
          setOpenRoleDialog(false);
        }}
      >
        <DialogTitle>{`${getValues("role_id") ? "Edit" : "Create"} role`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" mt={2} color="#888" sx={{ fontWeight: "bold" }}>
                    Role detail
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="role_desc"
                    type="text"
                    id="role_desc"
                    label="Role description"
                    required
                    readOnly
                    rules={{
                      required: "This field is required",
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    name="permissions"
                    id="permissions"
                    label="Permissions"
                    options={permissions}
                    labelKey="permission_desc"
                    valueKey="permission_id"
                    multiple
                    handleOnChange={(value: IPermission[]) => {
                      setSelectedPermissions(value);
                    }}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </Grid>
                {selectedPermissions?.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="subtitle2" mt={2} color="#888" sx={{ fontWeight: "bold" }}>
                          Permissions detail
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <DataGrid
                        autoHeight
                        rows={selectedPermissions || []}
                        columns={permissionColumns}
                        getRowId={(row) => row.permission_id}
                        disableSelectionOnClick
                        hideFooterPagination
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setOpenRoleDialog(false)}>
            Cancel
          </Button>
          <Button sx={{ mr: 2 }} variant="contained" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
