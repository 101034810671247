import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
  TextFieldProps,
} from "@mui/material";
import { FieldProps } from "./types";

export interface FormAsyncAutocompleteProps extends FieldProps {
  labelKey?: string;
  valueKey?: string;
  getOptions?: (searchTerm: string) => any;
}

export const FormAsyncAutocomplete = ({
  id,
  name,
  label,
  required,
  getOptions,
  labelKey,
  valueKey,
  defaultValue,
  control,
  errors,
  register,
  rules,
}: FormAsyncAutocompleteProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [asyncOptions, setOptions] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const loading = open && asyncOptions.length === 0;

  useEffect(() => {
    if (searchTerm && getOptions) {
      const delayDebounceFn = setTimeout(() => {
        (async () => {
          try {
            const response = await getOptions(searchTerm);
            setOptions(response?.data.results);
          } catch (error) {
            console.log("error", error);
          }
        })();
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ...restFields } }) => (
          <>
            <Autocomplete
              id={id}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              options={asyncOptions || []}
              loading={loading}
              isOptionEqualToValue={(option, value) => true}
              getOptionLabel={(option: any) => {
                return (labelKey ? option[labelKey] : option.label) ?? option;
              }}
              renderInput={(params) => (
                <TextField
                  {...(params as TextFieldProps)}
                  required={required}
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              {...(register && register(name, rules))}
              {...restFields}
              value={value || ""}
              onChange={(event: any, newValue: any, reason: string) => {
                if (reason === "clear") {
                  setOptions([]);
                }
                setSearchTerm("");
                onChange(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setSearchTerm(newInputValue);
              }}
            />
          </>
        )}
        shouldUnregister={true}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <Typography color="error">{message}</Typography>
        )}
      />
    </>
  );
};
