import { action, thunk, Action, Thunk } from "easy-peasy";

import TenantService from "../../services/tenant.service";
import { ITenant } from "../../types/api/tenant.type";

export interface ITenantModel {
  tenants: ITenant[];
  selectedTenant: ITenant;
  totalTenant: number;
  loading: boolean;
  setTenants: Action<this, ITenant[]>;
  setSelectedTenant: Action<this, ITenant>;
  setTotalTenant: Action<this, number>;
  setLoading: Action<this, boolean>;
  clearTenantState: Action<this>;
  getTenants: Thunk<this, any>;
  getTenantsByLocationId: Thunk<this, { locationId: string; data: any }>;
  createTenant: Thunk<this, ITenant>;
  editTenant: Thunk<this, { tenant_uuid: string; data: ITenant }>;
  deleteTenant: Thunk<this, string>;
}

export const tenant: ITenantModel = {
  tenants: [],
  selectedTenant: {} as ITenant,
  totalTenant: 0,
  loading: false,
  // ACTIONS
  setTenants: action((state, payload) => {
    state.tenants = payload;
  }),
  setSelectedTenant: action((state, payload) => {
    state.selectedTenant = payload;
  }),
  setTotalTenant: action((state, payload) => {
    state.totalTenant = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  clearTenantState: action((state) => {
    state.tenants = [];
    state.selectedTenant = {} as ITenant;
    state.totalTenant = 0;
  }),
  // THUNKS
  getTenants: thunk(async (actions, payload, helpers) => {
    try {
      actions.setLoading(true);
      const result: any = await TenantService.getTenants(payload);
      const tenants = result.data.results;
      actions.setTotalTenant(result.data.total);
      if (payload.page_no === 1) {
        actions.setTenants(tenants);
      } else {
        actions.setTenants([...helpers.getState().tenants, ...tenants]);
      }
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
      throw error;
    }
  }),
  getTenantsByLocationId: thunk(async (actions, payload, helpers) => {
    try {
      actions.setLoading(true);
      const result: any = await TenantService.getTenantsByLocationId(payload.locationId, payload.data);
      const tenants = result.data.results;
      actions.setTotalTenant(result.data.total);
      if (payload.data.page_no === 1) {
        actions.setTenants(tenants);
      } else {
        actions.setTenants([...helpers.getState().tenants, ...tenants]);
      }
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
      throw error;
    }
  }),
  createTenant: thunk(async (actions, payload) => {
    try {
      await TenantService.createTenant(payload);
    } catch (error) {
      throw error;
    }
  }),
  editTenant: thunk(async (actions, payload) => {
    try {
      const result: any = await TenantService.editTenant(payload.data, payload.tenant_uuid);
      actions.setSelectedTenant(result.data);
    } catch (error) {
      throw error;
    }
  }),
  deleteTenant: thunk(async (actions, payload) => {
    try {
      await TenantService.deleteTenant(payload);
      actions.setSelectedTenant({} as ITenant);
    } catch (error) {
      throw error;
    }
  }),
};
