import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const supportedLngs = ["en"];
const fallbackLng = "en";
i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
      requestOptions: {
        cache: "no-store",
      },
    },
    lng: "en",
    defaultNS: "common",
    fallbackLng,
    ns: ["common"],
    debug: false,
    supportedLngs,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
