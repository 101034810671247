import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface IColumnHeader {
  title: string;
  handleOnSearch: (searchTerm: string) => void;
  handleOnAdd: () => void;
}
function ColumnHeader({ title, handleOnSearch, handleOnAdd }: IColumnHeader, ref: any) {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchText, setSearchText] = useState("");

  useImperativeHandle(ref, () => ({
    resetSearch: () => {
      setSearchText("");
    },
  }));

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleOnSearch(searchText);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", p: 1, pb: 0 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography className={isInputFocused ? "titleOnFocus" : "titleOnBlur"}>{title}</Typography>
        <TextField
          id="input-with-icon-textfield"
          inputRef={ref}
          className={isInputFocused ? "searchOnFocus" : "searchOnBlur"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchText}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          onChange={(e: any) => {
            console.log("searchText", e.target.value);
            setSearchText(e.target.value);
          }}
          variant="standard"
        />
      </Box>
      <Button
        variant="text"
        onClick={() => {
          handleOnAdd();
        }}
        sx={{ width: 50 }}
      >
        + Add
      </Button>
    </Box>
  );
}

export default forwardRef(ColumnHeader);
