import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  // Switch,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";

const DetailCard = ({
  data,
  details,
  title,
  subheader,
  id,
  showDetailView,
  handleOnCardClick,
  handleEdit,
  handleDelete,
  hasAddEnv,
  handleAddEnv
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      id="fade-menu"
      MenuListProps={{
        "aria-labelledby": "fade-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <MenuItem
        onClick={() => {
          handleClose();
          handleEdit();
        }}
      >
        <EditIcon fontSize="small" sx={{ mr: 1 }} />
        Edit
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          handleClose();
          handleDelete();
        }}
      >
        <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
        Delete
      </MenuItem>
      {hasAddEnv && <Divider />}
      {hasAddEnv &&
        <MenuItem
          onClick={() => {
            handleClose();
            handleAddEnv();
          }}
        >
          <AddIcon fontSize="small" sx={{ mr: 1 }} />
          Add Environment
        </MenuItem>
      }
    </Menu>
  );

  const renderDetailList = () => {
    if (!details) {
      return;
    }
    if (!Array.isArray(details)) {
      const elementList = [];
      for (const [key, value] of Object.entries<any>(details)) {
        elementList.push(
          <ListItem key={key} sx={{ py: 0, minHeight: 32 }} disableGutters>
            <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>{value.icon}</ListItemIcon>
            <ListItemText secondary={`${value.count || 0} ${key}`} />
          </ListItem>
        );
      }
      return elementList;
    } else {
      return details;
    }
  };
  return (
    <Card
    className="detail-card"
      sx={
        showDetailView
          ? { background: "#395758", boxShadow: "none", borderRadius: "10px", px: "10px" }
          : { background: "#18191D", boxShadow: "none", px: "10px" }
      }
      key={id}
    >
      <CardHeader
        sx={{ pl: 0 }}
        avatar={
          <Avatar sx={{ bgcolor: "primary" }} aria-label="recipe">
            {title[0]}
          </Avatar>
        }
        action={
          <>
            {showDetailView && (
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </>
        }
        title={
          <Tooltip title={title} placement="top">
            <Typography
              sx={{
                cursor: "pointer",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "inline-block"
              }}
              onClick={() => {
                handleOnCardClick(data);
              }}
            >
              {title}
            </Typography>
          </Tooltip>
        }
        subheader={subheader}
      />
      <Divider />
      {showDetailView && (
        <CardContent sx={{ p: "0 !important" }}>
          {renderDetailList()}
          <Divider />
          {/* <ListItem sx={{ py: 0, minHeight: 32 }} disableGutters>
            <ListItemText secondary="Status: Active" />
            <Switch
              color="success"
              edge="end"
              // onChange={handleToggle()}
              // checked={checked.indexOf() !== -1}
            />
          </ListItem> */}
        </CardContent>
      )}
      {renderMenu}
    </Card>
  );
};

export default React.memo(DetailCard);
