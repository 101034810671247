import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getRoles = (data: any) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/roles?count_per_page=${data.count_per_page}&page_no=${data.page_no}&role_desc=${data.filter.role_desc}`);
};

const createRole = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/roles`, data);
};

const editRole = (data: any, role_id: number) => {
  return api.put(`${SERVICE_BASE_URLS.ACCOUNTS}/roles/${role_id}`, data);
};

const editRolePermissions = (data: any, role_id: number) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/roles/${role_id}/permissions`, data);
};

const deleteRole = (id: number) => {
  return api.delete(`${SERVICE_BASE_URLS.ACCOUNTS}/roles/${id}`);
};

const getRoleByID = (id: number) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/roles/${id}`);
};

const getPages = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/pages/fetchAll`, data);
};

const getObjects = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/objects/fetchAll`, data);
};

const getOperations = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/operation/fetchAll`, data);
};

const RoleService = {
  getRoles,
  createRole,
  editRole,
  editRolePermissions,
  deleteRole,
  getRoleByID,
  getPages,
  getObjects,
  getOperations,
};

export default RoleService;
