import { Grid, Typography } from "@mui/material";
import { UseFormRegister, FieldValues, Control } from "react-hook-form";
import { FormAutocomplete } from "./FormAutoComplete";
import { FormInput } from "./FormInput";
import { getTimeZones } from "../global/timezones";

interface OnboardFormProps {
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  errors: { [key: string]: any };
}

export default function OnboardForm({ control, register, errors }: OnboardFormProps) {
  const timezones = getTimeZones();

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" color="#888" sx={{ fontWeight: "bold" }}>
            Account details
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="account_name"
            type="string"
            id="account_name"
            label="Account name"
            rules={{
              required: "This field is required",
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" mt={2} color="#888" sx={{ fontWeight: "bold" }}>
            Address
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="address_line_1"
            type="string"
            id="address_line_1"
            label="Address Line 1"
            rules={{
              required: "This field is required",
            }}
            extraFieldData={{
              multiline: true,
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="address_line_2"
            type="string"
            id="address_line_2"
            label="Address Line 2"
            rules={{
              required: "This field is required",
            }}
            extraFieldData={{
              multiline: true,
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="city"
            type="string"
            id="city"
            label="City"
            rules={{
              required: "This field is required",
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="state"
            type="string"
            id="state"
            label="State"
            rules={{
              required: "This field is required",
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="country"
            type="string"
            id="country"
            label="Country"
            rules={{
              required: "This field is required",
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="postal"
            type="number"
            id="postal"
            label="Postal"
            rules={{
              required: "This field is required",
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" mt={2} color="#888" sx={{ fontWeight: "bold" }}>
            Domain config
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="subdomain"
            type="string"
            id="subdomain"
            label="Sub domain"
            rules={{
              required: "This field is required",
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" mt={2} color="#888" sx={{ fontWeight: "bold" }}>
            Organization details
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="org_name"
            type="text"
            id="org_name"
            label="Organization name"
            rules={{
              required: "This field is required",
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" mt={2} color="#888" sx={{ fontWeight: "bold" }}>
            Location details
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="loc_name"
            type="string"
            id="loc_name"
            label="Location name"
            rules={{
              required: "This field is required",
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <FormAutocomplete
            name="timezone"
            type="string"
            id="timezone"
            label="Location timezone"
            options={timezones}
            labelKey="label"
            valueKey="label"
            rules={{
              required: "This field is required",
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" mt={2} color="#888" sx={{ fontWeight: "bold" }}>
            Tenant details
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="tenant_name"
            type="string"
            id="tenant_name"
            label="Tenant name"
            rules={{
              required: "This field is required",
            }}
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
      </Grid>
    </form>
  );
}
