import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getAccounts = (data: any) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/accounts?count_per_page=${data.count_per_page}&page_no=${data.page_no}&account_name=${data.filter.account_name}`);
};

const getAccountById = (account_uuid: string) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/accounts/${account_uuid}`);
};

const createAccount = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/accounts`, data);
};

const editAccount = (data: any, account_uuid: string) => {
  return api.put(`${SERVICE_BASE_URLS.ACCOUNTS}/accounts/${account_uuid}`, data);
};

const deleteAccount = (account_uuid: string) => {
  return api.delete(`${SERVICE_BASE_URLS.ACCOUNTS}/accounts/${account_uuid}`);
};

const onboardAccount = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/accounts/onboard`, data);
};

const AccountService = {
  getAccounts,
  getAccountById,
  createAccount,
  editAccount,
  deleteAccount,
  onboardAccount,
};

export default AccountService;
