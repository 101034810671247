import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";


const getEnvironments = (data: any) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/environments?count_per_page=${data.count_per_page}&page_no=${data.page_no}&env_desc=${data.filter.env_desc}`, data);
};

const getEnvironmentById = (id: number) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/environments/${id}`);
};

const createEnvironment = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/environments`, data);
};

const editEnvironment = (data: any, environment_id: string) => {
  return api.put(`${SERVICE_BASE_URLS.ACCOUNTS}/environments/${environment_id}`, data);
};

const deleteEnvironment = (id: string) => {
  return api.delete(`${SERVICE_BASE_URLS.ACCOUNTS}/environments/${id}`);
};

const getEnvironmentsByTenantId = (tenantId: string) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/tenants/${tenantId}/environments`);
};

const getPhysicalEnvDetailsByEnvId = (envId: number) => {
  return api.get(`${SERVICE_BASE_URLS.DB_MANAGER}/db-environments/${envId}`);
};

const createPhysicalEnvironment = (data: any, options: object) => {
  return api.post(
    `${SERVICE_BASE_URLS.DB_MANAGER}/db-environments`,
    data,
    options
  );
};

const deletePhysicalEnv = (envId: number) => {
  return api.delete(`${SERVICE_BASE_URLS.DB_MANAGER}/db-environments/${envId}`);
};

const EnvironmentService = {
  getEnvironments,
  getEnvironmentById,
  createEnvironment,
  editEnvironment,
  deleteEnvironment,
  getEnvironmentsByTenantId,
  getPhysicalEnvDetailsByEnvId,
  createPhysicalEnvironment,
  deletePhysicalEnv
};

export default EnvironmentService;
