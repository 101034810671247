import * as React from "react";
import Link from "@mui/material/Link";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Badge from "@mui/material/Badge";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Card, CardContent } from "@mui/material";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const data = [
  {
    id: 1,
    name: "OCI",
    status: "UP",
    healthDashboard: "https://some.azure.link",
    teardownLink: "https://some.azure.link",
    logs: "https://some.azure.link",
    metrics: "https://some.azure.link",
  },
  {
    id: 2,
    name: "OCI",
    status: "UP",
    healthDashboard: "https://some.azure.link",
    teardownLink: "https://some.azure.link",
    logs: "https://some.azure.link",
    metrics: "https://some.azure.link",
  },
];

export default function Resources() {
  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ marginBottom: "20px" }}>
        <Typography color="#777" gutterBottom>
          {t("common:dashboard.Dashboard")}
        </Typography>
      </Breadcrumbs>
      <Card sx={{ backgroundColor: "#2A2B31", maxWidth: "850px" }}>
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <Typography gutterBottom component="h2" variant="h6">
            {t("common:resources.title")}
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <Typography>
                        <Badge
                          color={row.status === "UP" ? "success" : "error"}
                          variant="dot"
                          sx={{ marginRight: "16px !important" }}
                        />
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Link href={row.healthDashboard} color="#7AC231">
                        Health Dashboard
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Link href={row.logs} color="#79bcff">
                        Logs
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Link href={row.metrics} color="#79bcff">
                        Metrics
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Link href={row.teardownLink} color="error">
                        Teardown
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
