import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getDataplanes = () => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/dataplanes`);
};

const createDataplanes = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/dataplanes`, data);
};

const createBatchEnv = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/dataplanes/batch`, data);
};

const deleteBatchEnv = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/dataplanes/batch/delete`, data);
};

const suspendOrResumeBatchEnv = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/dataplanes/batch/suspendorresume`, data);
};

const promoteBatchEnv = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/dataplanes/batch/promote`, data);
};

const assignUserToEnv = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/dataplanes/batch/assign`, data);
};

const DataplaneService = {
  getDataplanes,
  createDataplanes,
  createBatchEnv,
  deleteBatchEnv,
  suspendOrResumeBatchEnv,
  promoteBatchEnv,
  assignUserToEnv,
};

export default DataplaneService;
