import { useState } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";

const CodeTextEditor = ({ language, minHeight }: { language: string; minHeight: number }) => {
  const [code, setCode] = useState(
    `# To contribute improvements to CI/CD templates, please follow the Development guide at:
# https://docs.gitlab.com/ee/development/cicd/templates.html
# This specific template is located at:
# https://gitlab.com/gitlab-org/gitlab/-/blob/master/lib/gitlab/ci/templates/Go.gitlab-ci.yml

image: golang:latest

variables:
  # Please edit to your GitLab project
  REPO_NAME: gitlab.com/namespace/project

# The problem is that to be able to use go get, one needs to put
# the repository in the $GOPATH. So for example if your gitlab domain
# is gitlab.com, and that your repository is namespace/project, and
# the default GOPATH being /go, then you'd need to have your
# repository in /go/src/gitlab.com/namespace/project
# Thus, making a symbolic link corrects this.
before_script:
  - mkdir -p "$GOPATH/src/$(dirname $REPO_NAME)"
  - ln -svf "$CI_PROJECT_DIR" "$GOPATH/src/$REPO_NAME"
  - cd "$GOPATH/src/$REPO_NAME"

stages:
  - test
  - build
  - deploy

format:
  stage: test
  script:
    - go fmt $(go list ./... | grep -v /vendor/)
    - go vet $(go list ./... | grep -v /vendor/)
    - go test -race $(go list ./... | grep -v /vendor/)

compile:
  stage: build
  script:
    - go build -race -ldflags "-extldflags '-static'" -o $CI_PROJECT_DIR/mybinary
  artifacts:
    paths:
      - mybinary
`
  );
  console.log("code ----", code);
  return (
    <>
      <CodeEditor
        value={code}
        language={language}
        minHeight={minHeight}
        placeholder="Please enter Yaml code."
        onChange={(evn) => setCode(evn.target.value)}
        padding={15}
        style={{
          fontSize: 13,
          fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
          overflow: "auto",
        }}
      />
    </>
  );
};

export default CodeTextEditor;
