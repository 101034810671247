import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { ErrorMessage } from "@hookform/error-message";
import { Typography } from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { FieldProps } from "./types";

export const FormDatePicker = ({
  id,
  name,
  label,
  defaultValue,
  readOnly,
  rules,
  control,
  register,
  errors,
}: FieldProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DesktopDateTimePicker
            label={label}
            renderInput={(params) => (
              <TextField
                id={id}
                fullWidth
                InputProps={{
                  readOnly,
                }}
                {...params}
              />
            )}
            {...field}
            {...(register && register(name, rules))}
            value={field.value}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            ampm={false}
          />
        )}
        shouldUnregister={true}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <Typography color="error" align="left">
            {message}
          </Typography>
        )}
      />
    </>
  );
};
