import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getUsers = (data: any) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/users?count_per_page=${data.count_per_page}&page_no=${data.page_no}&primary_email=${data.filter.primary_email}`);
};

const createUser = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/users`, data);
};

const editUser = (data: any, org_id: string) => {
  return api.put(`${SERVICE_BASE_URLS.ACCOUNTS}/users/${org_id}`, data);
};

const deleteUser = (id: string) => {
  return api.delete(`${SERVICE_BASE_URLS.ACCOUNTS}/users/${id}`);
};

const getUserByID = (id: string) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/users/${id}`);
};

const UserService = {
  getUsers,
  createUser,
  editUser,
  deleteUser,
  getUserByID,
};

export default UserService;
