import { action, thunk, Action, Thunk } from "easy-peasy";

import LocationService from "../../services/location.service";
import { ILocation, ILocationsResponse } from "../../types/api/location.type";

export interface ILocationModel {
  locations: ILocation[];
  selectedLocation: ILocation;
  totalLocation: number;
  loading: boolean;
  setLocations: Action<this, ILocation[]>;
  setSelectedLocation: Action<this, ILocation>;
  setTotalLocation: Action<this, number>;
  setLoading: Action<this, boolean>;
  clearLocationState: Action<this>;
  getLocations: Thunk<this, any>;
  getLocationsByOrganizationId: Thunk<this, { orgId: string, data: any }>;
  createLocation: Thunk<this, ILocation>;
  editLocation: Thunk<this, { loc_uuid: string; data: ILocation }>;
  deleteLocation: Thunk<this, string>;
}

export const location: ILocationModel = {
  locations: [],
  selectedLocation: {} as ILocation,
  totalLocation: 0,
  loading: false,
  // ACTIONS
  setLocations: action((state, payload) => {
    state.locations = payload;
  }),
  setSelectedLocation: action((state, payload) => {
    state.selectedLocation = payload;
  }),
  setTotalLocation: action((state, payload) => {
    state.totalLocation = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  clearLocationState: action((state) => {
    state.locations = [];
    state.selectedLocation = {} as ILocation;
    state.totalLocation = 0;
  }),
  // THUNKS
  getLocations: thunk(async (actions, payload, helpers) => {
    try {
      actions.setLoading(true);
      const result: ILocationsResponse = await LocationService.getLocations(payload);
      const locations = result.data.results;
      actions.setTotalLocation(result.data.total);
      if (payload.page_no === 1) {
        actions.setLocations(locations);
      } else {
        actions.setLocations([...helpers.getState().locations, ...locations]);
      }
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
      throw error;
    }
  }),
  getLocationsByOrganizationId: thunk(async (actions, payload, helpers) => {
    try {
      actions.setLoading(true);
      const result: ILocationsResponse = await LocationService.getLocationsByOrgId(payload.orgId, payload.data);
      const locations = result.data.results;
      actions.setTotalLocation(result.data.total);
      if (payload.data.page_no === 1) {
        actions.setLocations(locations);
      } else {
        actions.setLocations([...helpers.getState().locations, ...locations]);
      }
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
      throw error;
    }
  }),
  createLocation: thunk(async (actions, payload) => {
    try {
      await LocationService.createLocation(payload);
    } catch (error) {
      throw error;
    }
  }),
  editLocation: thunk(async (actions, payload) => {
    try {
      const result: any = await LocationService.editLocation(payload.data, payload.loc_uuid);
      actions.setSelectedLocation(result.data);
    } catch (error) {
      throw error;
    }
  }),
  deleteLocation: thunk(async (actions, payload) => {
    try {
      await LocationService.deleteLocation(payload);
      actions.setSelectedLocation({} as ILocation);
    } catch (error) {
      throw error;
    }
  }),
};
