import { action, thunk, Action, Thunk } from "easy-peasy";

import OrganizationService from "../../services/organization.service";
import { IOrganization, IOrganizationsResponse } from "../../types/api/organization.type";

export interface IOrganizationModel {
  organizations: IOrganization[];
  selectedOrganization: IOrganization;
  totalOrganization: number;
  loading: boolean;
  setOrganizations: Action<this, IOrganization[]>;
  setSelectedOrganization: Action<this, IOrganization>;
  setTotalOrganization: Action<this, number>;
  setLoading: Action<this, boolean>;
  clearOrganizationState: Action<this>;
  getOrganizations: Thunk<this, any>;
  getOrganizationsByAccountId: Thunk<this, { accountId: string; data: any }>;
  createOrganization: Thunk<this, IOrganization>;
  editOrganization: Thunk<this, { org_uuid: string; data: IOrganization }>;
  deleteOrganization: Thunk<this, string>;
}

export const organization: IOrganizationModel = {
  organizations: [],
  selectedOrganization: {} as IOrganization,
  totalOrganization: 0,
  loading: false,
  // ACTIONS
  setOrganizations: action((state, payload) => {
    state.organizations = payload;
  }),
  setSelectedOrganization: action((state, payload) => {
    state.selectedOrganization = payload;
  }),
  setTotalOrganization: action((state, payload) => {
    state.totalOrganization = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  clearOrganizationState: action((state) => {
    state.organizations = [];
    state.selectedOrganization = {} as IOrganization;
    state.totalOrganization = 0;
  }),
  // THUNKS
  getOrganizations: thunk(async (actions, payload, helpers) => {
    try {
      actions.setLoading(true);
      const result: IOrganizationsResponse = await OrganizationService.getOrganizations(payload);
      const organizations = result.data.results;
      actions.setTotalOrganization(result.data.total);
      if (payload.page_no === 1) {
        actions.setOrganizations(organizations);
      } else {
        actions.setOrganizations([...helpers.getState().organizations, ...organizations]);
      }
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
      throw error;
    }
  }),
  getOrganizationsByAccountId: thunk(async (actions, payload, helpers) => {
    try {
      actions.setLoading(true);
      const result: IOrganizationsResponse = await OrganizationService.getOrganizationsByAccountId(
        payload.accountId,
        payload.data
      );
      const organizations = result.data.results;
      actions.setTotalOrganization(result.data.total);
      if (payload.data.page_no === 1) {
        actions.setOrganizations(organizations);
      } else {
        actions.setOrganizations([...helpers.getState().organizations, ...organizations]);
      }
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
      throw error;
    }
  }),
  createOrganization: thunk(async (actions, payload, { getStoreActions }) => {
    try {
      await OrganizationService.createOrganization(payload);
      // getStoreActions().setSelectedAccount()
    } catch (error) {
      throw error;
    }
  }),
  editOrganization: thunk(async (actions, payload) => {
    try {
      const result: any = await OrganizationService.editOrganization(payload.data, payload.org_uuid);
      actions.setSelectedOrganization(result.data);
    } catch (error) {
      throw error;
    }
  }),
  deleteOrganization: thunk(async (actions, payload) => {
    try {
      await OrganizationService.deleteOrganization(payload);
      actions.setSelectedOrganization({} as IOrganization);
    } catch (error) {
      throw error;
    }
  }),
};
