import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Typography } from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { FieldProps } from "./types";

export interface FormTextEditorProps extends FieldProps {
  language: string;
  minHeight: number;
}

export const FormTextEditor = ({
  id,
  name,
  label,
  language,
  minHeight,
  control,
  defaultValue,
  readOnly,
  rules,
  register,
  errors,
  ...props
}: FormTextEditorProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, name } }) => (
          <CodeEditor
            id={id}
            language={language}
            minHeight={minHeight}
            placeholder="Please enter preference JSON."
            padding={15}
            style={{
              fontSize: 13,
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              overflow: "auto",
            }}
            readOnly={readOnly}
            value={value}
            {...(register && register(name, rules))}
          />
        )}
        shouldUnregister={true}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <Typography color="error" align="left">
            {message}
          </Typography>
        )}
      />
    </>
  );
};
