import { dataplane, DataplaneModel } from "./dataplanes";
import { account, IAccountModel } from "./account";
import { organization, IOrganizationModel } from "./organization";
import { location, ILocationModel } from "./location";
import { tenant, ITenantModel } from "./tenant";
import { environment, IEnvironmentModel } from "./environment";

export interface StoreModel {
  dataplane: DataplaneModel;
  account: IAccountModel;
  organization: IOrganizationModel;
  location: ILocationModel;
  tenant: ITenantModel;
  environment: IEnvironmentModel;
}

const models: StoreModel = {
  dataplane: dataplane,
  account: account,
  organization: organization,
  location: location,
  tenant: tenant,
  environment: environment,
};

export default models;
