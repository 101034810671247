import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const rows = [
  {
    id: 1,
    name: "OCI",
    status: "Running",
    healthDB: "https://some.azure.link",
    tearLink: "https://some.azure.link",
    logs: "https://some.azure.link",
    metrics: "https://some.azure.link",
  },
];

export default function Resources() {
  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="#777" gutterBottom>
        {t("common:dataplanes.Resources")}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Health Dashboard</TableCell>
            <TableCell>Teardown link</TableCell>
            <TableCell>Logs</TableCell>
            <TableCell>Metrics</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>
                <Link href={row.healthDB}>Health Check</Link>
              </TableCell>
              <TableCell>
                <Link href={row.tearLink}>Teardown</Link>
              </TableCell>
              <TableCell>
                <Link href={row.logs}>Logs</Link>
              </TableCell>
              <TableCell>
                <Link href={row.metrics}>Metrics</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
