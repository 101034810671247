import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getLocations = (data: any) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/locations?count_per_page=${data.count_per_page}&page_no=${data.page_no}&loc_name=${data.filter.loc_name}`);
};

const getLocationById = (id: string) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/locations/${id}`);
};

const createLocation = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/locations`, data);
};

const editLocation = (data: any, loc_id: string) => {
  return api.put(`${SERVICE_BASE_URLS.ACCOUNTS}/locations/${loc_id}`, data);
};

const deleteLocation = (id: string) => {
  return api.delete(`${SERVICE_BASE_URLS.ACCOUNTS}/locations/${id}`);
};

const getLocationsByOrgId = (orgId: string, data: any) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/organizations/${orgId}/locations`);
};

const LocationService = {
  getLocations,
  getLocationById,
  createLocation,
  editLocation,
  deleteLocation,
  getLocationsByOrgId,
};

export default LocationService;
