import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const hasUser = isAuthenticated && user;

  return hasUser ? (
    <Card sx={{ maxWidth: "1000px", padding: "20px", backgroundColor: "#16181C" }}>
      <img src={user.picture} alt={user.name} />
      <Stack
        component="form"
        sx={{
          width: "25ch",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
        <br />
        <TextField hiddenLabel id="username" label="Name" variant="standard" defaultValue={user.name} size="medium" />
        <TextField
          hiddenLabel
          id="useremail"
          label="Email"
          variant="standard"
          defaultValue={user.email}
          size="medium"
        />
      </Stack>
    </Card>
  ) : (
    <></>
  );
};

export default Profile;
