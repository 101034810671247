import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";

let _tokenFetcher: () => Promise<string>;

const apiService = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
});

apiService.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    config.headers = config.headers || {};
    if (!_tokenFetcher) {
      return Promise.resolve({
        ...config,
        // eslint-disable-next-line import/no-named-as-default-member
        cancelToken: new axios.CancelToken((cancel) =>
          cancel(
            "Axios API was not initialized with the appropriate token fetching function."
          )
        ),
      } as unknown as InternalAxiosRequestConfig);
    }
    try {
      const jwt = await _tokenFetcher();
      return Promise.resolve({
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${jwt}` },
      } as unknown as InternalAxiosRequestConfig);
    } catch (e) {
      return Promise.resolve({
        ...config,
        // eslint-disable-next-line import/no-named-as-default-member
        cancelToken: new axios.CancelToken((cancel) =>
          cancel("No access token!")
        ),
      } as unknown as InternalAxiosRequestConfig);
    }
  },
  (error: Error) => Promise.reject(error)
);

apiService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response?.data || error.response);
  }
);

export default apiService;

/**
 * Provide the ability to pass the getAccessTokenSilently function from Auth0 when the app bootstraps.
 * @param tokenFetcher
 */
export const setTokenFetcher = (tokenFetcher: () => Promise<string>) => {
  _tokenFetcher = tokenFetcher;
  return this;
};
