import React, { createContext, useState, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert, AlertColor } from "@mui/material";

export interface INotification {
  message?: string;
  type?: AlertColor;
  open?: boolean;
}

export const NotificationContext = createContext({
  notification: {},
  setNotification: (notification: INotification) => {},
});

const useNotifications = () => useContext(NotificationContext);

export default useNotifications;

export function NotificationProvider({ children }: any) {
  const [notification, setNotification] = useState<INotification>({
    message: "",
    type: "success",
    open: false,
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification({ open: false });
  };

  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={notification.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={notification.type || "success"}
          sx={{ minWidth: "300px" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
}
