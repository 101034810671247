import { Controller } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ErrorMessage } from "@hookform/error-message";
import { Typography } from "@mui/material";
import { FieldProps } from "./types";

export interface FormSelectProps extends FieldProps {
  labelKey?: string;
  valueKey?: string;
}

export const FormSelect = ({
  id,
  name,
  label,
  options,
  labelKey,
  valueKey,
  defaultValue,
  control,
  errors,
  register,
  rules,
}: FormSelectProps): JSX.Element => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <>
            <FormControl fullWidth>
              <InputLabel id={`${id}-label`}>{label}</InputLabel>
              <Select
                labelId={`${id}-label`}
                id={id}
                defaultValue=""
                value={value || defaultValue || ""}
                label={label}
                MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
                {...(register && register(name, rules))}
              >
                {options &&
                  options.map((option: any) => (
                    <MenuItem
                      key={option.id || option.label}
                      value={valueKey ? option[valueKey] : option.value}
                    >
                      {labelKey ? option[labelKey] : option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </>
        )}
        shouldUnregister={true}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <Typography color="error">{message}</Typography>
        )}
      />
    </>
  );
};
