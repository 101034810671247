import { useState, useEffect } from "react";
import { UseFormRegister, FieldValues, Control } from "react-hook-form";
import {
  Grid,
  Typography,
  TableCell,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";
import { FormInput } from "./FormInput";
import { FormSelect } from "./FormSelect";
import { FieldProps } from "./types";

interface IGroupValues {
  name: string;
  type: string;
}

interface IFormRenderer {
  formData: any;
  formControl: Control<FieldValues, any>;
  formRegister: UseFormRegister<FieldValues>;
  formErrors: { [key: string]: any };
}

export const FormRenderer = ({
  formData,
  formControl,
  formRegister,
  formErrors,
}: IFormRenderer): JSX.Element => {
  const [formFields, setFormFieldsData] = useState([]);
  const formInputs: any = {
    text: (data: FieldProps) => <FormInput {...data} />,
    select: (data: FieldProps) => <FormSelect {...data} />,
    number: (data: FieldProps) => <FormInput {...data} />,
    password: (data: FieldProps) => <FormInput {...data} />,
    group: (data: IGroupValues) => (
      <Typography
        variant="subtitle2"
        mt={2}
        color="#888"
        sx={{ fontWeight: "bold" }}
      >
        {data.name}
      </Typography>
    ),
  };

  const inputTypes: { [key: string]: string } = {
    string: "text",
    options: "select",
    integer: "number",
    secret: "password",
    label: "text",
    group: "group",
    info: "info",
  };

  useEffect(() => {
    constructFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const constructFormData = () => {
    const constructedData = parseData(formData, []);
    setFormFieldsData(constructedData);
  };

  const parseData = (obj: any, result: any = [], group: string = "") => {
    for (const [key, value] of Object.entries<any>(obj)) {
      if (value.type && value?.type !== "group" && value?.type !== "grid") {
        result.push(parseObject(key, value, group));
      } else if (value.type === "group") {
        result.push({
          name: key,
          type: value.type,
          fields: parseData(
            value,
            [],
            `${group ? `${group}.${key}` : `${key}`}`
          ),
        });
      } else if (value.type === "grid") {
        result.push({
          name: key,
          type: value.type,
          fields: parseData(value, [], key),
        });
      }
    }
    return result;
  };

  const parseObject = (key: string, value: any, group: string = "") => {
    const result = {
      name: `${group ? `${group}.${key}` : `${key}`}`,
      id: `${group ? `${group}.${key}` : `${key}`}`,
      type: inputTypes[value.type] || "text",
      label: value.displayName || key,
      options: constructOptions(value.possibleValues) || [],
      defaultValue: value.default || "",
      readOnly: value.type === "label" ? true : false,
      extraFieldData: value.extraData,
      register: formRegister,
      control: formControl,
      errors: formErrors,
      rules: {
        required: "This field is required",
        min: {
          value: value.min,
          message: `Value should be minimum ${value.min}`,
        },
        max: {
          value: value.max,
          message: `Value should be maximum ${value.max}`,
        },
      },
    };
    return result;
  };

  const constructOptions = (data: Array<string>) => {
    if (!data) return [];
    return data.map((value: string) => {
      return { value, label: value };
    });
  };

  const renderGrid = (gridData: Array<any>) => (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableBody>
        {gridData.map((row) => (
          <TableRow
            key={row.name}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            {row.fields.map((field: any) => (
              <TableCell key={field.name} align="center">
                {formInputs[field.type](field)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <>
      {formFields.map((data: any) => {
        if (data.type === "grid") {
          return (
            <>
              <Grid key={data.name} item xs={12}>
                <Typography
                  variant="subtitle2"
                  mt={2}
                  color="#888"
                  sx={{ fontWeight: "bold" }}
                >
                  {data.name}
                </Typography>
              </Grid>
              {renderGrid(data.fields)}
            </>
          );
        } else if (data.type === "group") {
          return (
            <>
              <Grid key={data.name} item xs={12}>
                <Typography
                  variant="subtitle2"
                  mt={2}
                  color="#888"
                  sx={{ fontWeight: "bold" }}
                >
                  {data.name}
                </Typography>
              </Grid>
              {data.fields.map((data: any) => (
                <Grid key={data.name} item xs={4}>
                  {formInputs[data.type](data)}
                </Grid>
              ))}
            </>
          );
        } else {
          return (
            <Grid key={data.name} item xs={4}>
              {formInputs[data.type](data)}
            </Grid>
          );
        }
      })}
    </>
  );
};
