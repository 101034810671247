import { action, thunk, Action, Thunk } from "easy-peasy";
import axios from "axios";

export interface DataplaneModel {
  dataplanes: any[];
  setDataplane: Action<this, any[]>;
  saveDataPlanes: Thunk<this, any>;
}

export const dataplane: DataplaneModel = {
  dataplanes: [],
  // ACTIONS
  setDataplane: action((state, payload) => {
    state.dataplanes = payload;
  }),
  // THUNKS
  saveDataPlanes: thunk(async (actions, payload) => {
    try {
      const res: any = await axios({
        method: "POST",
        url: "/api/dataplane/config",
        data: payload,
      });
      const dataplane = await res.data;
      actions.setDataplane([]);
      console.log("saved--", dataplane);
    } catch (error) {
      console.log("Error occurred");
    }
  }),
};
