import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getPermissions = (data: any) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/permissions?count_per_page=${data.count_per_page}&page_no=${data.page_no}&permission_name=${data.filter.permission_name}`);
};

const getALLPermissions = () => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/permissions`);
};

const createPermission = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/permissions`, data);
};

const editPermission = (data: any, permission_id: number) => {
  return api.put(`${SERVICE_BASE_URLS.ACCOUNTS}/permissions/${permission_id}`, data);
};

const deletePermission = (id: number) => {
  return api.delete(`${SERVICE_BASE_URLS.ACCOUNTS}/permissions/${id}`);
};

const getPermissionByID = (id: number) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/permissions/${id}`);
};

const PermissionService = {
  getPermissions,
  getALLPermissions,
  createPermission,
  editPermission,
  deletePermission,
  getPermissionByID,
};

export default PermissionService;
