import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getTenants = (data: any) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/tenants?count_per_page=${data.count_per_page}&page_no=${data.page_no}&tenant_name=${data.filter.tenant_name}`);
};

const getTenantById = (id: string) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/tenants/${id}`);
};

const createTenant = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/tenants`, data);
};

const editTenant = (data: any, tenant_id: string) => {
  return api.put(`${SERVICE_BASE_URLS.ACCOUNTS}/tenants/${tenant_id}`, data);
};

const deleteTenant = (id: string) => {
  return api.delete(`${SERVICE_BASE_URLS.ACCOUNTS}/tenants/${id}`);
};

const getTenantsByLocationId = (locationId: string, data: any) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/locations/${locationId}/tenants?count_per_page=${data.count_per_page}&page_no=${data.page_no}&tenant_name=${data.filter.tenant_name}`);
};

const getTenantEnvironmentById = (tenantId: string) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/tenants/${tenantId}/environments`);
};

const TenantService = {
  getTenants,
  getTenantById,
  createTenant,
  editTenant,
  deleteTenant,
  getTenantsByLocationId,
  getTenantEnvironmentById,
};

export default TenantService;
