import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { FormInput } from "./FormInput";

interface IConfirmationDialog {
  open: boolean;
  setOpen: (open: boolean) => void;
  onProceed: () => void;
  title: string;
  content: string;
  confirmationText?: string;
}

export const ConfirmationDialog = ({
  open,
  setOpen,
  onProceed,
  title,
  content,
  confirmationText,
}: IConfirmationDialog) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useForm({});
  const watchConfirm = watch("confirm", "");

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText component={"div"} id="alert-dialog-description">
          <Typography>{content}</Typography>
          {confirmationText && (
            <>
              <Typography my={2}>Type "{confirmationText}" to proceed.</Typography>
              <Box sx={{ width: "300px" }}>
                <FormInput
                  name="confirm"
                  id="confirm"
                  label="Confirmation"
                  type="text"
                  rules={{
                    required: "This field is required",
                  }}
                  control={control}
                  register={register}
                  errors={errors}
                />
              </Box>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onProceed();
          }}
          variant="contained"
          disabled={confirmationText ? watchConfirm !== confirmationText : false}
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};
