import { Controller } from "react-hook-form";
import { FormControlLabel, Checkbox } from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import { Typography } from "@mui/material";
import { FieldProps } from "./types";

export const FormCheckbox = ({
  id,
  name,
  label,
  control,
  defaultValue,
  readOnly,
  rules,
  register,
  errors,
  ...props
}: FieldProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                id={id}
                {...field}
                {...(register && register(name, rules))}
                value={Boolean(field.value)}
                checked={Boolean(field.value)}
              />
            }
            label={label}
          />
        )}
        shouldUnregister={true}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <Typography color="error" align="left">
            {message}
          </Typography>
        )}
      />
    </>
  );
};
