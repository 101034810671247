import { action, thunk, Action, Thunk } from "easy-peasy";

import AccountService from "../../services/account.service";
import { IAccount, IAccountsResponse } from "../../types/api/accounts.type";

export interface IAccountModel {
  accounts: IAccount[];
  selectedAccount: IAccount;
  totalAccount: number;
  loading: boolean;
  setAccounts: Action<this, IAccount[]>;
  setSelectedAccount: Action<this, IAccount>;
  setTotalAccount: Action<this, number>;
  setLoading: Action<this, boolean>;
  clearAccountState: Action<this>;
  getAccounts: Thunk<this, any>;
  createAccount: Thunk<this, IAccount>;
  editAccount: Thunk<this, { data: IAccount; account_uuid: string }>;
  deleteAccount: Thunk<this, string>;
}

export const account: IAccountModel = {
  accounts: [],
  selectedAccount: {} as IAccount,
  totalAccount: 0,
  loading: false,
  // ACTIONS
  setAccounts: action((state, payload) => {
    state.accounts = payload;
  }),
  setSelectedAccount: action((state, payload) => {
    state.selectedAccount = payload;
  }),
  setTotalAccount: action((state, payload) => {
    state.totalAccount = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  clearAccountState: action((state) => {
    state.accounts = [];
    state.selectedAccount = {} as IAccount;
    state.totalAccount = 0;
  }),
  // THUNKS
  getAccounts: thunk(async (actions, payload, helpers) => {
    try {
      actions.setLoading(true);
      const result: IAccountsResponse = await AccountService.getAccounts(payload);
      const accounts = result.data.results;
      actions.setTotalAccount(result.data.total);
      if (payload.page_no === 1) {
        actions.setAccounts(accounts);
      } else {
        actions.setAccounts([...helpers.getState().accounts, ...accounts]);
      }
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
      throw error;
    }
  }),
  createAccount: thunk(async (actions, payload) => {
    try {
      await AccountService.createAccount(payload);
    } catch (error) {
      throw error;
    }
  }),
  editAccount: thunk(async (actions, payload) => {
    try {
      const result: any = await AccountService.editAccount(payload.data, payload.account_uuid);
      actions.setSelectedAccount(result.data);
    } catch (error) {
      throw error;
    }
  }),
  deleteAccount: thunk(async (actions, payload) => {
    try {
      await AccountService.deleteAccount(payload);
      actions.setSelectedAccount({} as IAccount);
    } catch (error) {
      throw error;
    }
  }),
};
