import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "../../utils/config";

interface AuthProps {
  children: JSX.Element;
}

const Auth0ProviderWithHistory = ({ children }: AuthProps) => {
  const config = getConfig();
  const domain = config.domain || "";
  const clientId = config.clientId || "";
  const audience = config.audience || "";

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      scope="read:current_user read:user update:current_user_metadata"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
