import { action, thunk, Action, Thunk } from "easy-peasy";

import EnvironmentService from "../../services/environment.service";
import { IEnvironment, IPhysicalEnvironment } from "../../types/api/environment.type";

export interface IEnvironmentModel {
  environments: IEnvironment[];
  physicalEnvironment: IPhysicalEnvironment;
  selectedEnvironment: IEnvironment;
  totalEnvironment: number;
  loading: boolean;
  setEnvironments: Action<this, IEnvironment[]>;
  setPhysicalEnvironment: Action<this, IPhysicalEnvironment>;
  setSelectedEnvironment: Action<this, IEnvironment>;
  setTotalEnvironment: Action<this, number>;
  setLoading: Action<this, boolean>;
  clearEnvironmentState: Action<this>;
  getEnvironments: Thunk<this, any>;
  getEnvironmentsByTenantId: Thunk<this, { tenantId: string }>;
  getPhysicalEnvDetailsByEnvId: Thunk<this, { envId: number }>;
  createEnvironment: Thunk<this, IEnvironment>;
  editEnvironment: Thunk<this, { environment_id: string; data: IEnvironment }>;
  deleteEnvironment: Thunk<this, string>;
  createPhysicalEnvironment: Thunk<this, any>;
  deletePhysicalEnvironment: Thunk<this, number>;
}

export const environment: IEnvironmentModel = {
  environments: [],
  physicalEnvironment: {} as IPhysicalEnvironment,
  selectedEnvironment: {} as IEnvironment,
  totalEnvironment: 0,
  loading: false,
  // ACTIONS
  setEnvironments: action((state, payload) => {
    state.environments = payload;
  }),
  setPhysicalEnvironment: action((state, payload) => {
    state.physicalEnvironment = payload;
  }),
  setSelectedEnvironment: action((state, payload) => {
    state.selectedEnvironment = payload;
  }),
  setTotalEnvironment: action((state, payload) => {
    state.totalEnvironment = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  clearEnvironmentState: action((state) => {
    state.environments = [];
    state.selectedEnvironment = {} as IEnvironment;
    state.totalEnvironment = 0;
  }),
  // THUNKS
  getEnvironments: thunk(async (actions, payload, helpers) => {
    try {
      actions.setLoading(true);
      const result: any = await EnvironmentService.getEnvironments(payload);
      const environments = result.data.results;
      actions.setTotalEnvironment(result.data.total);
      if (payload.page_no === 1) {
        actions.setEnvironments(environments);
      } else {
        actions.setEnvironments([
          ...helpers.getState().environments,
          ...environments,
        ]);
      }
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
      throw error;
    }
  }),
  getEnvironmentsByTenantId: thunk(async (actions, payload, helpers) => {
    try {
      actions.setLoading(true);
      const result: any = await EnvironmentService.getEnvironmentsByTenantId(
        payload.tenantId
      );
      const environments = result.data.results;
      actions.setTotalEnvironment(result.data.total);
      actions.setEnvironments(environments);
      // actions.setEnvironments([
      //   ...helpers.getState().environments,
      //   ...environments,
      // ]);
      actions.setLoading(false);
    } catch (error) {
      actions.setLoading(false);
      throw error;
    }
  }),
  getPhysicalEnvDetailsByEnvId: thunk(async (actions, payload) => {
    try {
      actions.setLoading(true);
      const result: any = await EnvironmentService.getPhysicalEnvDetailsByEnvId(
        payload.envId
      );
      actions.setPhysicalEnvironment(result.data);
      actions.setLoading(false);
    } catch (error) {
      actions.setPhysicalEnvironment({} as IPhysicalEnvironment);
      actions.setLoading(false);
      throw error;
    }
  }),
  createEnvironment: thunk(async (actions, payload) => {
    try {
      await EnvironmentService.createEnvironment(payload);
    } catch (error) {
      throw error;
    }
  }),
  createPhysicalEnvironment: thunk(async (actions, payload) => {
    try {
      await EnvironmentService.createPhysicalEnvironment(
        payload.data,
        payload.options
      );
    } catch (error) {
      throw error;
    }
  }),
  editEnvironment: thunk(async (actions, payload) => {
    try {
      const result: any = await EnvironmentService.editEnvironment(
        payload.data,
        payload.environment_id
      );
      actions.setSelectedEnvironment(result.data);
    } catch (error) {
      throw error;
    }
  }),
  deleteEnvironment: thunk(async (actions, payload) => {
    try {
      await EnvironmentService.deleteEnvironment(payload);
      actions.setSelectedEnvironment({} as IEnvironment);
    } catch (error) {
      throw error;
    }
  }),
  deletePhysicalEnvironment: thunk(async (actions, payload) => {
    try {
      await EnvironmentService.deletePhysicalEnv(payload);
      actions.setSelectedEnvironment({} as IEnvironment);
    } catch (error) {
      throw error;
    }
  }),
};
