import { ReactNode, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Container, Box, Typography, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import Stream from "./stream";
import Batch from "./batch";

export interface ITableRowsState {
  page: number;
  pageSize: number;
}
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dataplane-panel-${index}`}
      aria-labelledby={`dataplane-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `dataplane-${index}`,
    "aria-controls": `dataplane-panel-${index}`,
  };
};

const AccountsLayout = () => {
  const { t } = useTranslation("common");
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        sx={{ marginBottom: "20px" }}
      >
        <Typography color="#777" gutterBottom>
          {t("common:dataplanes.title")}
        </Typography>
      </Breadcrumbs>
      <Container>
        <Box component="main">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Stream" {...a11yProps(0)} />
              <Tab label="Batch" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Stream />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Batch />
          </TabPanel>
        </Box>
      </Container>
    </>
  );
};

export default AccountsLayout;
