import { SERVICE_BASE_URLS } from "../global/constants";
import api from "./api";

const getOrganizations = (data: any) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/organizations?count_per_page=${data.count_per_page}&page_no=${data.page_no}&org_name=${data.filter.org_name}`);
};

const getOrganizationById = (id: string) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/organizations/${id}`);
};

const createOrganization = (data: any) => {
  return api.post(`${SERVICE_BASE_URLS.ACCOUNTS}/organizations`, data);
};

const editOrganization = (data: any, org_id: string) => {
  return api.put(`${SERVICE_BASE_URLS.ACCOUNTS}/organizations/${org_id}`, data);
};

const deleteOrganization = (id: string) => {
  return api.delete(`${SERVICE_BASE_URLS.ACCOUNTS}/organizations/${id}`);
};

const getOrganizationsByAccountId = (accountId: string, data: any) => {
  return api.get(`${SERVICE_BASE_URLS.ACCOUNTS}/accounts/${accountId}/organizations?count_per_page=${data.count_per_page}&page_no=${data.page_no}&org_name=${data.filter.org_name}`);
};

const OrganizationService = {
  getOrganizations,
  getOrganizationById,
  createOrganization,
  editOrganization,
  deleteOrganization,
  getOrganizationsByAccountId,
};

export default OrganizationService;
