import { ReactNode, useEffect, useState } from "react";
import Link from "@mui/material/Link";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Badge from "@mui/material/Badge";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Box,
  Tabs,
  Tab,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CodeTextEditor from "../../components/CodeEditor";
import DataplaneService from "../../services/dataplane.service";
import { useLocation } from "react-router-dom";
import { FormRenderer } from "../../components/FormRenderer";

import { useStoreState, useStoreActions } from "../../store/hooks";

const data = [
  {
    id: 1,
    name: "OCI",
    status: "UP",
    healthDashboard: "https://some.azure.link",
    teardownLink: "https://some.azure.link",
    logs: "https://some.azure.link",
    metrics: "https://some.azure.link",
  },
  {
    id: 2,
    name: "OCI",
    status: "UP",
    healthDashboard: "https://some.azure.link",
    teardownLink: "https://some.azure.link",
    logs: "https://some.azure.link",
    metrics: "https://some.azure.link",
  },
];

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dataplane-panel-${index}`}
      aria-labelledby={`dataplane-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `dataplane-${index}`,
    "aria-controls": `dataplane-panel-${index}`,
  };
};

const Dataplanes = () => {
  const { t } = useTranslation("common");
  const [tabValue, setTabValue] = useState(0);
  const [openConfigDialog, setConfigDialogOpen] = useState(false);
  const { state } = useLocation();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const { dataplanes } = useStoreState((store) => store.dataplane);
  const { saveDataPlanes } = useStoreActions((store) => store.dataplane);

  useEffect(() => {
    console.log("dataplanes", dataplanes);
  });

  useEffect(() => {
    console.log("state---", state);
    getDataplaneDetails();
  }, [state]);

  const getDataplaneDetails = async () => {
    try {
      const dataplanes = await DataplaneService.getDataplanes();
      console.log("databases---", dataplanes);
      // setData(mockdata);
    } catch (e) {
      console.error("----", e);
      // setData(mockdata);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      const streamingJobs = [];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries<any>(values.streamingJobs)) {
        streamingJobs.push(value);
      }
      values.streamingJobs = { jobs: streamingJobs };
      saveDataPlanes(values);
      // const dataplanes = await DataplaneService.createDataplanes(values);
      setConfigDialogOpen(false);
    } catch (e) {
      console.error("----", e);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const formData = {
    location: {
      type: "string",
      default: "east-us",
    },
    iotHub: {
      type: "options",
      default: "S1",
      possibleValues: ["B1", "B2", "B3", "S1", "S2", "S3"],
    },
    branchName: {
      type: "string",
      default: "main",
    },
    database: {
      url: {
        type: "string",
        default: "testdb.eastus.azure.io",
      },
      user: {
        type: "string",
        default: "tsdbadmin",
      },
      password: {
        type: "secret",
        default: null,
      },
      dbName: {
        type: "string",
        default: "tsdb",
      },
      type: "group",
    },
    streamingJobs: {
      IngestUptime: {
        name: {
          type: "label",
          default: "IngestUptime",
        },
        su: {
          type: "integer",
          default: 3,
          min: 1,
          max: 60,
        },
        outputErrorPolicy: {
          type: "string",
          default: "Retry",
        },
        eventsOutOfOrderPolicy: {
          type: "string",
          default: "Adjust",
        },
        type: "group",
      },
      type: "grid",
    },
    eventHubs: {
      partitions: {
        type: "integer",
        min: 2,
        max: 60,
      },
      type: "group",
    },
    storage: {
      sku: {
        type: "options",
        default: "B1",
        possibleValues: ["B1", "B2", "B3", "S1", "S2", "S3"],
      },
      type: "group",
    },
  };

  return (
    <>
      <Card sx={{ backgroundColor: "#2A2B31" }}>
        <CardHeader
          action={
            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              onClick={() => {
                setConfigDialogOpen(true);
              }}
            >
              Create
            </Button>
          }
          title={t("common:dataplanes.title")}
        />
        <CardContent sx={{ paddingBottom: "16px !important" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography>
                        <Badge
                          color={row.status === "UP" ? "success" : "error"}
                          variant="dot"
                          sx={{ marginRight: "16px !important" }}
                        />
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Link href={row.healthDashboard} color="#7AC231">
                        Health Dashboard
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Link href={row.logs} color="#79bcff">
                        Logs
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Link href={row.metrics} color="#79bcff">
                        Metrics
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Link href={row.teardownLink} color="error">
                        Teardown
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={openConfigDialog}
        onClose={() => setConfigDialogOpen(false)}
      >
        <DialogTitle>Create Dataplane</DialogTitle>
        <Divider />
        <DialogContent>
          <Box component="main">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Configuration" {...a11yProps(0)} />
                <Tab label="Yaml" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <form>
                <Grid
                  container
                  spacing={2}
                  mt={1}
                  pr={2}
                  pb={2}
                  sx={{ overflow: "auto" }}
                >
                  <FormRenderer
                    formData={formData}
                    formControl={control}
                    formRegister={register}
                    formErrors={errors}
                  />
                </Grid>
              </form>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Box>
                <CodeTextEditor language={"yml"} minHeight={700} />
              </Box>
            </TabPanel>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ mr: 2 }} onClick={() => setConfigDialogOpen(false)}>
            Close
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Provision
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Dataplanes;
