import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { ErrorMessage } from "@hookform/error-message";
import { Typography } from "@mui/material";
import { FieldProps } from "./types";

export const FormInput = ({
  id,
  name,
  label,
  type,
  required,
  helperText,
  defaultValue,
  readOnly,
  rules,
  control,
  register,
  errors,
  ...props
}: FieldProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            required={required}
            autoComplete="off"
            id={id}
            label={label}
            type={type}
            helperText={helperText}
            InputProps={{ readOnly }}
            {...field}
            value={field.value || ""}
            {...(register && register(name, rules))}
            {...props.extraFieldData}
          />
        )}
        shouldUnregister={true}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <Typography color="error" align="left">
            {message}
          </Typography>
        )}
      />
    </>
  );
};
