import React, { createContext, useState, useContext } from "react";
import { Box, CircularProgress } from "@mui/material";

export const LoaderContext = createContext({
  loading: {},
  setLoading: (loading: boolean) => {},
});

const useLoader = () => useContext(LoaderContext);

export default useLoader;

export function LoaderProvider({ children }: any) {
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      {loading && (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            position: "absolute",
            justifyContent: "center",
            width: "100%",
            zIndex: 10000,
            backdropFilter: "blur(2px)",
          }}
        >
          <CircularProgress color="primary" />
          Loading...
        </Box>
      )}
      {children}
    </LoaderContext.Provider>
  );
}
